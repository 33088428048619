//== DEFAULTS RAILS
import Rails from "@rails/ujs"
Rails.start()


// //== JQUERY
import $ from 'jquery'
window.$ = window.jQuery = $

//== BOOTSTRAP
import { Popper } from '@popperjs/core'
import * as bootstrap from 'bootstrap'


// FIXED HEADER Schatten
$(window).on('load scroll resize orientationchange', function () {
    let fixedtop = $('.fixed-top');

    if ($(document).scrollTop()) {
        fixedtop.addClass("fixed-top shadow");
    }

});

